import styles from './MyComponent.module.css';
import React, { useState } from 'react';
import { __ } from '@wordpress/i18n';

/**
 * Functional React Component to display the block on the front end
 *
 * @param {*} props - Block attributes
 * @returns {string} - The component html
 */
export function MyComponent(props) {
  const [message, setMessage] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const check_password = event.target.elements.check_password.value;
    const response = await fetch('/wp-json/panomity-darkweb-press/v1/check-password', {
      method: 'POST',
      body: JSON.stringify({ check_password }),
      headers: {
        'Content-Type': 'application/json',
      },
    });

    const result = await response.json();
    if (JSON.stringify(result).indexOf('1') !== -1) {
      setMessage('Oh no! This password was found on the dark web.');
    } else {
      setMessage('Great! This password was not found on the dark web. Please remember to never reuse passwords and stay safe!');
    }
    setLoading(false);
  };

  return (
    <div
      className="panomity-darkweb-press-block-container-in-front-end"
      style={{
        backgroundColor: props.bgColor,
        borderColor: props.borderColor,
        textAlign: props.headlineAlignment,
      }}
    >
      <h2>{props.headline}</h2>
      {loading ? (
        <div className={styles.spinner}>
          <div className={styles.bounce1}></div>
          <div className={styles.bounce2}></div>
          <div className={styles.bounce3}></div>
        </div>
      ) : (
        <p className={message.includes('Oh no') ? 'text-danger' : 'text-success'}>{message}</p>
      )}
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label id="label_for_check_password" className="required" htmlFor="check_password">Password</label>
          <input type="password" id="check_password" name="check_password" className="input-password" required />
        </div>
        <button className="button primary-button" type="submit">
          {__('Submit', 'panomity-darkweb-press')}
        </button>
      </form>
    </div>
  );
}
